export default [
  {
    header: 'Paramètres',
    icon: 'HomeIcon',
    image: 'setting.png',
    children: [
      {
        title: 'Canaux de distribution',
        route: 'parametres-canaux-distribution',
        icon: 'SettingsIcon',
      },
      {
        title: 'Produits-Type',
        route: 'parametres-produits-type',
        icon: 'SettingsIcon',
      },
      {
        title: 'Produit-Saveurs',
        route: 'parametres-produit-saveurs',
        icon: 'SettingsIcon',
      },
      // {
      //   title: 'Permissions-Pages',
      //   route: 'parametres-psermissions-pages',
      //   icon: 'SettingsIcon',
      // },
      {
        title: 'Visites Actions',
        route: 'parametres-actions',
        icon: 'SettingsIcon',
      },
      {
        title: 'Produits-Afridia',
        route: 'parametres-produits-afridia',
        icon: 'SettingsIcon',
      },
      {
        title: 'Produits-Concurrence',
        route: 'parametres-produits-concurrence',
        icon: 'SettingsIcon',
      },
      {
        title: 'Arrondissement',
        route: 'parametres-arrondissement',
        icon: 'SettingsIcon',
      },
      // {
      //   title: 'Configuration',
      //   route: 'parametres-configurations',
      //   icon: 'SettingsIcon',
      // },
      {
        title: 'Profiles',
        route: 'parametres-profiles',
        icon: 'SettingsIcon',
      },
      // {
      //   title: 'Permissions',
      //   route: 'parametres-permissions',
      //   icon: 'ShieldIcon',
      // },
      {
        title: 'Utilisateurs',
        route: 'parametres-utilisateurs',
        icon: 'UsersIcon',
      },
    ],
  },
]
