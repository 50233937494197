export default [
  {
    header: 'Distributeurs',
    icon: 'HomeIcon',
    image: 'box.png',
    children: [
      {
        title: 'Liste des distributeurs',
        route: 'distributeurs-liste',
        icon: 'ListIcon',
      },
      {
        title: 'Liste des visites',
        route: 'distributeurs-visites',
        icon: 'ListIcon',
      },
      // {
      //   title: 'Liste des objectifs',
      //   route: 'distributeurs-objectifs',
      //   icon: 'ListIcon',
      // },
    ],
  },
]
